<template>
  <div class="qiqb-table">
    <div class="qiqb-table-item">
      <div class="table-operator">
        <div class="table-operator-title">{{ name }}</div>
      </div>
      <div class="table-item">
        <a-table :columns="columns" :data-source="item" :pagination="page" size="middle" @change="more">
					<span slot="hot" slot-scope="text">
						<span v-if="text==1"><a-icon type="fire" /></span>
						<span v-if="text==0"></span>
					</span>	
          <span slot="action" slot-scope="text, record">
            <a @click="handleEdit(record)">编辑</a>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 提交表单 -->
    <create-form ref="createModal" @ok="handleOk" />
  </div>
</template>

<script>
import CreateForm from "./modules/CreateForm";
import { config_city, config_city_add, config_city_del } from "../../../api/index.js";

export default {
  components: {
    CreateForm,
  },
  methods: {
    async more(e) {
			this.queryParam.page = e.current
      let res = await config_city(this.queryParam);
      if (res.code == 200) {
        this.item = res.data.data;
        this.page.total = res.data.total;
        this.page.current = res.data.current_page;
      }
    },
    handleEdit(record) {
      this.id = record.id;
      this.$refs.createModal.init(record);
    },
    handleOk(e) {
			config_city_add({id:this.id,item:e}).then((res)=>{
				if(res.code==200){
					this.$message.success(res.msg);
					this.$refs.createModal.cancel();
					this.more({ current: this.page.current })
				}else{
					this.$message.error(res.msg);
				}
			})   
    },
    del(e) {
			config_city_del({id:e.id}).then((res)=>{
				if(res.code==200){
					this.$message.success(res.msg);
					this.more({ current: this.page.current })
				}else{
					this.$message.error(res.msg);
				}
			})
		}
  },
  mounted() {
    this.more({ current: 1 });
  },
  data() {
    return {
      name: "",
      id: 0,
      queryParam: {},
      page: { pageSize: "50", total: 0, current: 0 },
      columns: [
        { title: "编号", dataIndex: "id", width: 100 },
        { title: "名称", dataIndex: "title" },	
				{ title: "首字母", dataIndex: "name", width: 100 },
				{ title: "热门", dataIndex: "hot", width: 100, scopedSlots: { customRender: "hot" } },
				{ title: "排序", dataIndex: "sort", width: 100 },
        { title: "操作", width:60, scopedSlots: { customRender: "action" } },
      ],
      item: [],
			city:[]
    };
  },
  props: {
    msg: String,
  },
  watch: {
    $route: {
      handler: function (route) {
        this.name = route.name;
      },
      immediate: true,
    },
  },
};
</script>