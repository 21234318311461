<template>
  <a-modal :title="'城市设置'" :width="640" :visible="visible" @ok="ok"  @cancel="cancel" >
    <a-form :form="form" v-bind="formLayout">
			<a-form-item label="隐藏字段" style="display:none;">
				<a-input v-decorator="[ 'pid' ]" />
			</a-form-item>
      <a-form-item label="城市名称">
        <a-input v-decorator="[ 'title', { rules: [{ required: true, message: '' }] }]" />
      </a-form-item>
			<a-form-item label="首字母">
			  <a-select v-decorator="[ 'name', { rules: [{ required: true, message: '' }] }]">
			  	<a-select-option :value="v" v-for="v in name" v-bind:key="v">{{v}}</a-select-option>
			  </a-select>
			</a-form-item>
			<a-form-item label="是否热门">
				<a-radio-group v-decorator="[ 'hot', { rules: [{ required: true, message: '' }] }]">
					<a-radio :value="1">是</a-radio>
					<a-radio :value="0">否</a-radio>
				</a-radio-group>
			</a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import pick from "lodash.pick";

// 表单字段
const fields = ["title", "pid", "hot", "name"] 

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
    };
    return {
      form: this.$form.createForm(this),
			name:["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","W","V","X","Y","Z"]
    };
  },
  created() {
    fields.forEach((v) => this.form.getFieldDecorator(v))
  },
  methods: {
    init(e) {
      this.form.setFieldsValue(pick(e, fields));
      this.visible = true;
    },
    cancel() {
      this.visible = false;
			this.form.resetFields();
    },
    ok() {
      this.form.validateFields((errors, values) => {
        if (!errors){
					this.$emit("ok", values)
				}else{
					this.form.resetFields()
				}
      });
    },
  },
}
</script>
